import React from 'react'
import { Link } from 'gatsby'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/styles/withStyles'

import { connect } from 'react-redux'
import { passwordSet } from '../../actions'

import { intlIdToString } from '../00-utils/u-translation'
import AButton from '../01-atoms/a-button'
import AInput from '../01-atoms/a-input'
import AForm from '../01-atoms/a-form'

const styles = theme => ({
  buttonClass: {
    ...theme.customStyles.profileSubmit,
  },
  hint: {
    marginTop: theme.typography.pxToRem(25),
  },
})

class OSetPasswordForm extends AForm {
  afterSubmit() {
    this.props.passwordSet(this.getValues(false))
  }

  render() {
    const { classes, resetToken, username } = this.props
    const { apiResults } = this.props.data
    return (
      <form
        onSubmit={this.handleSubmit}
        className={this.state.formValid ? '' : 'has-error'}
      >
        <AInput
          name="key"
          onEnter={name => this.fieldRegister(name, resetToken)}
          required
          type="hidden"
          value={
            this.state.fields.resetToken !== undefined
              ? this.state.fields.resetToken
              : ''
          }
          InputProps={{ disableUnderline: true }}
        />
        <AInput
          name="username"
          onEnter={name => this.fieldRegister(name, username)}
          required
          type="hidden"
          value={
            this.state.fields.username !== undefined
              ? this.state.fields.username.value
              : ''
          }
          InputProps={{ disableUnderline: true }}
        />
        <Grid container spacing={3} justify="center">
          <Grid item xs={12} sm={10} md={6}>
            <AInput
              error={this.fieldHasError('pass1')}
              label={intlIdToString('newPassword')}
              name="pass1"
              onChange={this.handleChange.bind(this, ['isValidPassword'])}
              onEnter={name => this.fieldRegister(name)}
              required
              type="password"
              value={
                this.state.fields.pass1 !== undefined
                  ? this.state.fields.pass1.value
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12} sm={10} md={6}>
            <AInput
              error={this.fieldHasError('pass2')}
              label={intlIdToString('repeatPassword')}
              name="pass2"
              onChange={this.handleChange.bind(this, ['isValidPassword'])}
              onEnter={name => this.fieldRegister(name)}
              required
              type="password"
              value={
                this.state.fields.pass2 !== undefined
                  ? this.state.fields.pass2.value
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12} sm={10} md={12}>
            <AButton
              className={classes.buttonClass}
              variant="contained"
              type="submit"
            >
              {intlIdToString('save')}
            </AButton>
          </Grid>

          {apiResults !== false && (
            <Grid item xs={12} sm={10} md={6}>
              <Typography component="p" className={classes.hint}>
                Das Passwort wurde erfolgreich gesetzt. Sie können Sich nun{' '}
                <Link to="/login/">einloggen</Link>.
              </Typography>
            </Grid>
          )}
        </Grid>
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    data: {
      apiResults: state.apiResults,
      currentlySending: state.currentlySending,
    },
  }
}

const mapDispatchToProps = dispatch => {
  return {
    passwordSet: data => dispatch(passwordSet(data)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(OSetPasswordForm))
