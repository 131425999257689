import React from 'react'
import SimpleTemplateWrapper from '../../components/04-templates/t-simple-template-wrapper'
import OPasswordSetForm from '../../components/03-organisms/o-password-set-form.js'
import queryString from 'query-string'
import { Typography } from '@material-ui/core'

import APaper from '../../components/01-atoms/a-paper'

class SetPasswordPage extends React.Component {
  render() {
    // Needed for build: here we need to check if window is defined, because
    // it is not while building gatsby
    const parsed =
      typeof window === 'undefined' ? {} : queryString.parse(location.search)

    return (
      <SimpleTemplateWrapper showHeadlineBar headline="Passwort setzen">
        <APaper>
          {parsed.action === 'rp' &&
          typeof parsed.key === 'string' &&
          parsed.key.length &&
          typeof parsed.login === 'string' &&
          parsed.login.length ? (
            <OPasswordSetForm resetToken={parsed.key} username={parsed.login} />
          ) : (
            <Typography variant="p">Invalid Link</Typography>
          )}
        </APaper>
      </SimpleTemplateWrapper>
    )
  }
}
export default SetPasswordPage
